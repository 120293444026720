import { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import OpenAI from 'openai';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
  mode: 'no-cors',
});

const SUPPORTED_LANGUAGES = [
  'en-EN',
  'es-ES',
];

const Dictaphone = () => {
  const {
    transcript,
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const [language, setLanguage] = useState(SUPPORTED_LANGUAGES[0]);
  const [messages, setMessages] = useState([]);


  const createCompletion = async (conversation) => {
    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: "You are being interviewed for a JavaScript Engineering position. You are going to get questions and you should reply the interviewers questions in short answers."
        },
        ...conversation,
      ],
    });

    setMessages((prev) => [
      ...prev,
      completion.choices[0].message,
    ]);
  }

  useEffect(() => {
    if (finalTranscript !== '') {
      const conversation = [
        ...messages,
        {
          role: 'user',
          content: finalTranscript,
        },
      ];

      setMessages(conversation);
      createCompletion(conversation);
      resetTranscript();
    }
  }, [messages, finalTranscript, resetTranscript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <Container>
      <p>Microphone: {listening ? 'on' : 'off'}</p>
      <button
        onClick={() => SpeechRecognition.startListening({
          language,
        })}
      >
        Start
      </button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      <button onClick={resetTranscript}>Reset</button>
      <FormControl fullWidth>
        <InputLabel id="lang-select-label">Language</InputLabel>
        <Select
          labelId="lang-select-label"
          id="lang-select"
          value={language}
          label="Language"
          onChange={(e) => setLanguage(e.target.value)}
        >
          {SUPPORTED_LANGUAGES.map((lang) => (
            <MenuItem key={lang} value={lang}>{lang}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <p>{transcript}</p>

      <Stack spacing={2}>
      {messages.map((message, index) => (
        <Card key={index} p={10}>
          <CardContent>
            <Typography variant="body1">
              {message.content}
            </Typography>
          </CardContent>
        </Card>
      ))}
      </Stack>
    </Container>
  );
};

export default Dictaphone;
