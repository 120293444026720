import {Grid2 as Grid } from '@mui/material';

import Layout from 'lib/components/Layout';
import {base} from 'themes';

import Dictaphone from './Dictaphone';

const Page = () => (
  <Layout theme={base}>
    <Grid container pt={10}>
      <Grid size={12}>
        <Dictaphone />
      </Grid>
    </Grid>
  </Layout>
);

export default Page;
